import {createRouter, createWebHistory} from 'vue-router';

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/login', component: () => import('@/pages/login/Login')},
        {path: '/', component: () => import('@/pages/user/userIndex')},
    ]
    // other options...
})
